import cupping from "../assets/images/homepage/treatments/cupping.webp";
import kinesio from "../assets/images/homepage/treatments/kinesio.webp";
import manual_therapy from "../assets/images/homepage/treatments/manual_therapy.webp";
import massageImg from "../assets/images/homepage/treatments/massageImg.webp";
import personal_plan from "../assets/images/homepage/treatments/personal_plan.webp";

const homepage_data = {
  intro: {
    content:
      "Humánkineziológusi végzettségem és számos célirányos szakmai továbbképzésem révén többféle módon tudok segíteni a problémáidon. Minden kezelésem egy alapos felméréssel kezdődik, így személyre szabottan tudok javítani a fájdalmaidon. Legyen szó sportmasszázsról, trigger masszázsról, köpölyözésről vagy akár egy közérzetjavító svéd masszázsról.",
  },
  gift_card: {
    content:
      "Lepje meg barátait, kollégáit vagy szeretteit ReFit Terápia ajándékkártyával!",
  },
  about_me: {
    content:
      "Ezen túlmenően, igény szerint személyi edzőként segíthetek a sérülések és fájdalmak megelőzésében, az újbóli problémák elkerülésében, valamint a rehabilitációban is. Ha otthoni edzésprogramra van szükséged, annak érdekében, hogy kondiban maradj akár a home-office időszakban is, abban is a segítségedre tudok lenni.",
  },
  prices: {
    content:
      "Az első találkozás alkalmával minden új vendégem számára ingyenes, részletes egészségi állapotfelmérést biztosítok (15-20 perc).",
  },
  contact: {
    content:
      "Kérlek, ne habozz felvenni velem a kapcsolatot, ha bármilyen kérdésed vagy igényed van. Örülök, ha segíthetek a jólléted megörzésében!",
  },
  treatments: {
    title: "Neked miben tudok segíteni?",
    service: {
      massage: {
        _id: "1",
        title: "Masszázs",
        img: massageImg,
        path: "/masszazs",
      },
      cup: {
        _id: "2",
        title: "Köpölyterápia",
        img: cupping,
        path: "/kopolyterapia",
      },
      kinezio: {
        _id: "3",
        title: "Kinezio tape",
        img: kinesio,
        path: "/kinezio-tape",
      },
      manual_therapy: {
        _id: "4",
        title: "Manuál terápia",
        img: manual_therapy,
        path: "/manual-terapia",
      },
      personal_plan: {
        _id: "5",
        title: "Személyi edzésterv",
        img: personal_plan,
        path: "/szemelyi-edzesterv",
      },
    },
  },
};

export default homepage_data;
